import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
// import * as components from 'vuetify/components';
// import * as directives from 'vuetify/directives';
import { en, zhHans } from 'vuetify/locale';
import { AQUA_THEME, BLUE_THEME, CYAN_THEME, GREEN_THEME, ORANGE_THEME, PURPLE_THEME } from '@/theme/LightTheme';
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';

export default createVuetify({
    // components,
    // directives,
    components: {
        VDataTableServer,
        VSkeletonLoader
    },

    locale: {
        locale: 'en',
        fallback: 'en',
        messages: { en, zhHans }
    },
    theme: {
        defaultTheme: 'BLUE_THEME',
        themes: {
            BLUE_THEME,
            AQUA_THEME,
            PURPLE_THEME,
            GREEN_THEME,
            CYAN_THEME,
            ORANGE_THEME
        }
    },
    defaults: {
        VCard: {
            rounded: 'md'
        },
        VTextField: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VTextarea: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VSelect: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VListItem: {
            minHeight: '45px'
        },
        VTooltip: {
            location: 'top'
        }
    }
});
