import '@/bootstrap';
import vuetify from './plugins/vuetify';
import Router from '@/router';
import store from '@/store';
import mitt from 'mitt';
import { ZiggyVue } from 'ziggy';
import { Ziggy } from './ziggy';
import { createApp } from 'vue/dist/vue.esm-bundler';
import '@sass/app.scss';
import VueScrollTo from 'vue-scrollto';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import * as Sentry from '@sentry/vue';

// vue-block-tree
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

import { createI18n } from 'vue-i18n';
import messages from '@/lang/messages';
const i18n = createI18n({
    locale: 'en',
    messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    warnHtmlInMessage: 'off'
});
window.i18n = i18n;

const app = createApp({});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(Router)
        }),
        new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

app.config.globalProperties.emitter = mitt();
app.use(ZiggyVue, Ziggy);
app.use(Router);
app.use(store);
app.use(PerfectScrollbar);
app.use(vuetify);
app.use(VueScrollTo, {
    duration: 1000,
    easing: 'ease'
});
app.use(i18n);
app.use(VueBlocksTree, { treeName: 'blocks-tree' });
app.mount('#app');
