const AuthRoutes = {
    path: '/',
    component: () => import('@/components/Layouts/Layout.vue'),
    meta: {},
    children: [
        {
            name: 'auth.login',
            path: '/',
            component: () => import('@/components/Auth/Login.vue'),
            meta: {
                middleware: 'guest',
                title: 'Login'
            }
        },
        {
            name: 'auth.register',
            path: '/register',
            component: () => import('@/components/Auth/Register.vue'),
            meta: {
                middleware: 'guest',
                title: 'Register'
            }
        },
        {
            name: 'auth.verify',
            path: '/email/verify/:user_id',
            component: () => import('@/components/Auth/Verify.vue'),
            meta: {
                middleware: 'guest',
                title: 'Verify'
            }
        },
        {
            name: 'auth.verification',
            path: '/email/verification',
            component: () => import('@/components/Auth/Verification.vue'),
            meta: {
                title: 'Verification'
            }
        },
        {
            name: 'auth.forgot-password',
            path: '/forgot-password',
            component: () => import('@/components/Auth/ForgotPassword.vue'),
            meta: {
                middleware: 'guest',
                title: 'Forgot Password'
            }
        },
        {
            name: 'auth.reset-password',
            path: '/password/reset/:token',
            component: () => import('@/components/Auth/ResetPassword.vue'),
            meta: {
                middleware: 'guest',
                title: 'Reset Password'
            }
        }
    ]
};

export default AuthRoutes;
