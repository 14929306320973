const MainRoutes = {
    path: '/',
    component: () => import('@/components/Layouts/Layout.vue'),
    meta: {},
    children: [
        {
            path: '/',
            component: () => import('@/components/Layouts/Default.vue'),
            children: [
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: () => import('@/components/Dashboards/Dashboard.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Dashboard'
                    }
                },
                {
                    name: 'account',
                    path: '/account',
                    component: () => import('@/components/Accounts/Account.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Account'
                    }
                },
                {
                    name: 'position',
                    path: '/position',
                    component: () => import('@/components/Positions/PositionList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Positions'
                    }
                },
                {
                    name: 'profile',
                    path: '/profile',
                    component: () => import('@/components/Profiles/Profile.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Profile'
                    }
                },
                {
                    name: 'downline',
                    path: '/downline',
                    component: () => import('@/components/Downlines/DownlineList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Downlines'
                    }
                }
            ]
        }
    ]
};

export default MainRoutes;
